import * as _ from "lodash"

export const getCurrentlyReading = rawData => {
  const edges = _.get(rawData, ["currentlyReading", "edges"])
  const currentlyReading = edges.length > 0 && edges[0].node
  return currentlyReading
}

export const getBookProgress = rawData => {
  const currentlyReading = getCurrentlyReading(rawData)
  const progress = _.get(currentlyReading, ["progress"], {})
  return progress
}
