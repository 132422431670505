import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import { Body, Box, Heading1 } from "../../helpers/styledComponents"
import {
  getCurrentlyReading,
  getBookProgress,
} from "../../helpers/dataTransformers/books"
import ProgressBar from "./progressBar"
import theme from "../../helpers/theme"

const CurrentlyReading = ({ background, showProgressBar }) => {
  const data = useStaticQuery(fragment)
  const currentlyReading = getCurrentlyReading(data)
  const progress = getBookProgress(data)

  return (
    <Box width="100%" background={background || theme.colors.lychee}>
      <Box display="flex" justifyContent="center" px={[4, 5]} py="4">
        <Box
          maxWidth={`${theme.maxWidth[3]}px`}
          display="flex"
          flex="1"
          width="100%"
          height="fit-content"
          flexDirection={["column", "row"]}
        >
          <Box
            flex="1"
            display="flex"
            flexDirection="column"
            alignItems="stretch"
          >
            <Box width="100%" pb={[3, 0]}>
              <Img fluid={currentlyReading.bookCover.asset.fluid} />
            </Box>
          </Box>
          <Box
            display="flex"
            flex="1"
            flexDirection="column"
            alignItems={["flex-start", "flex-end"]}
            justifyContent="center"
            pt={[3, 0]}
          >
            <Box>
              <Body textAlign={["left", "right"]}>Currently reading</Body>
            </Box>
            <Box pt={[2, 3]}>
              <Heading1 textAlign={["left", "right"]}>
                {currentlyReading.title}
              </Heading1>
            </Box>
            {showProgressBar && progress && progress.length > 0 && (
              <ProgressBar progress={progress} />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export const fragment = graphql`
  query currentlyReadingFragment {
    currentlyReading: allSanityBook(
      filter: { currentlyReading: { eq: true } }
      limit: 1
      sort: { order: DESC, fields: _createdAt }
    ) {
      edges {
        node {
          currentlyReading
          title
          author {
            details {
              name
            }
          }
          bookCover {
            asset {
              fluid(maxWidth: 800) {
                ...GatsbySanityImageFluid
              }
            }
          }
          progress {
            member {
              details {
                name
              }
            }
            progress
          }
        }
      }
    }
  }
`

export default CurrentlyReading
