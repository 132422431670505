import * as _ from "lodash"

export const getMemberName = rawData => {
  return _.get(rawData, ["details", "name"])
}

export const getShelfTitleForMember = rawData => {
  return `${getMemberName(rawData)}'s Picks`
}

export const getShelfSlugForMember = rawData => {
  const slug = _.get(rawData, ["details", "slug", "current"], "")
  return `/shelf/${slug}`
}

export const getFavoriteBooksForMember = rawData => {
  return _.get(rawData, "favoriteBooks", [])
}
