import React from "react"

import { Box } from "../../helpers/styledComponents"
import CurrentlyReading from "../widgets/currentlyReading"
import Favorites from "../widgets/favorites"
import theme from "../../helpers/theme"

const Shelf = () => {
  return (
    <Box>
      <Box py={[0, 4]}>
        <CurrentlyReading
          background={theme.colors.white}
          showProgressBar={true}
        />
      </Box>
      <Favorites />
    </Box>
  )
}

export default Shelf
