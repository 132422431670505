import React from "react"
import * as _ from "lodash"

import { Box, Heading3 } from "../../helpers/styledComponents"
import theme from "../../helpers/theme"
import useDimensions from "../../helpers/hooks/useDimensions"

const ProgressBar = ({ progress }) => {
  const [ref, dimensions] = useDimensions({ liveMeasure: true })
  const width = _.get(dimensions, "width", 0)

  return (
    <Box
      width="100%"
      pt={[3, 5]}
      pl={[0, 5]}
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
    >
      <Box
        py={3}
        borderBottom={`1px solid ${theme.colors.caramel}`}
        width={["100%", "320px"]}
        ref={ref}
      >
        <Heading3 color={theme.colors.caramel}>Progress:</Heading3>
      </Box>
      {progress.map((item, index) => {
        const {
          member: {
            details: { name },
          },
          progress: percentage,
        } = item
        return (
          <Box
            key={`${name}_${index}`}
            py={3}
            borderBottom={`1px dotted ${theme.colors.caramel}`}
            width={`${width * (percentage / 100)}px`}
            display="flex"
          >
            <Heading3 color={theme.colors.caramel}>{name}</Heading3>
            <Heading3
              color={theme.colors.caramel}
              pl="4"
            >{`${percentage}%`}</Heading3>
          </Box>
        )
      })}
    </Box>
  )
}

export default ProgressBar
