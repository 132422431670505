import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import BookRow from "../layout/bookRow"
import { Box } from "../../helpers/styledComponents"
import { getInverseColor } from "../../helpers/dataTransformers/colors"
import {
  getFavoriteBooksForMember,
  getShelfTitleForMember,
  getShelfSlugForMember,
} from "../../helpers/dataTransformers/member"
import Section from "../layout/section"
import theme from "../../helpers/theme"

const Favorites = () => {
  const data = useStaticQuery(fragment)
  const backgroundColor = theme.colors.caramel
  const inverseColor = getInverseColor(backgroundColor)
  const members = data.allSanityMember.edges

  return (
    <Box>
      <Section title="Our Faves" background={backgroundColor}>
        <Box px={[4, 5]}>
          {members.map((item, index) => {
            const { node } = item
            return (
              <BookRow
                books={getFavoriteBooksForMember(node).slice(0, 4)}
                title={getShelfTitleForMember(node)}
                textColor={inverseColor}
                url={getShelfSlugForMember(node)}
              />
            )
          })}
        </Box>
      </Section>
    </Box>
  )
}

const fragment = graphql`
  query favoritesFragment {
    allSanityMember(filter: {}, sort: { order: ASC, fields: _createdAt }) {
      edges {
        node {
          details {
            slug {
              current
            }
            name
          }
          favoriteBooks {
            bookCover {
              asset {
                fluid(maxWidth: 600) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            title
          }
        }
      }
    }
  }
`

export default Favorites
