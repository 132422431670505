import React from "react"
import { Box, Divider, Heading1 } from "../../helpers/styledComponents"
import { getInverseColor } from "../../helpers/dataTransformers/colors"
import theme from "../../helpers/theme"

const Section = ({ background, children, title }) => {
  const backgroundColor = background || theme.colors.caramel
  const inverseColor = getInverseColor(backgroundColor)
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="100%"
      background={backgroundColor}
      py={[4, 5]}
    >
      <Heading1 color={inverseColor}>{title}</Heading1>
      <Box width="100%" py={[3, 4]}>
        <Divider color={inverseColor} />
      </Box>
      <Box width="100%" py={3}>
        {children}
      </Box>
    </Box>
  )
}

export default Section
