import React from "react"
import Img from "gatsby-image"

import { Box, Heading2, SiteLink } from "../../helpers/styledComponents"
import theme from "../../helpers/theme"

const BookRow = ({ books, title, url, textColor, noPaddingTop }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      width="100%"
      pt={noPaddingTop ? 0 : [4, 5]}
    >
      <Box maxWidth={`${theme.maxWidth[4]}px`} width="100%">
        {title && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            pb="3"
            px={[2, 3]}
          >
            <Heading2 color={textColor} pr={3}>
              {title}
            </Heading2>
            {url && (
              <SiteLink to={url} style={{ color: textColor }}>
                See all
              </SiteLink>
            )}
          </Box>
        )}
        <Box display="flex" justifyContent="flex-start" flexWrap="wrap">
          {books.map((item, index) => {
            return (
              <Box
                key={`${title}_book_row_${index}`}
                flex={["0 0 50%", "0 0 25%"]}
                p={[2, 3]}
              >
                <Box>
                  <Img fluid={item.bookCover.asset.fluid} />
                </Box>
              </Box>
            )
          })}
        </Box>
      </Box>
    </Box>
  )
}

export default BookRow
