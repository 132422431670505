import React from "react"
import * as _ from "lodash"

import About from "../components/pages/about"
import Layout from "../components/layout/base"
import SEO from "../components/seo"
import Shelf from "../components/pages/shelf"

const SitePageTemplate = ({ data }) => {
  const pageSlug = _.get(data, ["sanitySitePage", "url", "current"], "")
  return (
    <Layout>
      <SEO title={data.sanitySitePage.name.toLowerCase()} />
      {pageSlug === "about" && <About />}
      {pageSlug === "shelf" && <Shelf />}
    </Layout>
  )
}

export const pageQuery = graphql`
  query sitePageTemplateQuery($id: String!) {
    sanitySitePage(id: { eq: $id }) {
      url {
        current
      }
      id
      content {
        sanityChildren {
          text
          marks
          _type
          _key
        }
        list
      }
      hidden
      name
    }
  }
`

export default SitePageTemplate
