import theme from "../theme"

export const getInverseColor = color => {
  switch (color) {
    case theme.colors.caramel:
      return theme.colors.white
    case theme.colors.lychee:
      return theme.colors.black
    case theme.colors.beige:
      return theme.colors.black
    default:
      return theme.colors.black
  }
}
