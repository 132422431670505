import React from "react"

import { Body, Box, Heading1, Heading3 } from "../../helpers/styledComponents"
import theme from "../../helpers/theme"

const About = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      px={[4, 5]}
      pt={[3, 6]}
      pb={[3, 5]}
    >
      <Box maxWidth={`${theme.maxWidth[3]}px`} width="100%">
        <Box pt={[4, 0]}>
          <Heading1 fontSize={[6, 10]} color="caramel">
            Who / What / Why
          </Heading1>
        </Box>
        <Box
          pt={[3, 6]}
          pb="3"
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
        >
          <Box width={["100%", "70%"]}>
            <Heading3 py={[2, 3]}>A LITTLE BIT ABOUT US</Heading3>
            <Body py={[2, 3]}>
              Hello, we are Lesley and Flora, two friends who share a love of
              reading. During the 2020 COVID-19 crisis when staying at home
              became a national mandate, we decided to start a book club
              together to find some quality content to consume. We hoped this
              would grow into a longer term affair, not only a passion project
              but also a way for us to keep up our long-distance friendship
              (since Lesley lives in Boston and Flora in San Francisco).
            </Body>
            <Body py={[2, 3]}>
              Although GoodReads is a great resource and provides a sense of
              community, we wanted to create our own web platform as we both
              enjoy coding. This site is an archive of our reads and
              discussions. Enjoy!
            </Body>
            <Body py={[2, 3]}>
              If you have any recommendations or suggestions, feel free to reach
              out to us.
            </Body>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default About
